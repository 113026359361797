// app/frontend/entrypoints/controllers/signup_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["name", "nickname", "greeting", "pretext", "posttext", "dobInput", "ageError", "isOfAge", "form", "password", "passwordConfirmation", "passwordError"]
  static classes = ["invalid"]

  connect() {
    this.validateAge = this.validateAge.bind(this)
    this.validatePasswords = this.validatePasswords.bind(this)
  }

  validatePasswords() {
    const password = this.passwordTarget.value
    const confirmation = this.passwordConfirmationTarget.value

    // Only validate if both fields have values and neither has focus
    if (password &&
        confirmation &&
        document.activeElement !== this.passwordTarget &&
        document.activeElement !== this.passwordConfirmationTarget) {

      if (password !== confirmation) {
        this.passwordConfirmationTarget.classList.add(this.invalidClass)
        this.passwordErrorTarget.style.display = 'block'
        this.passwordConfirmationTarget.setCustomValidity('Passwords must match')
      } else {
        this.passwordConfirmationTarget.classList.remove(this.invalidClass)
        this.passwordErrorTarget.style.display = 'none'
        this.passwordConfirmationTarget.setCustomValidity('')
      }
    } else {
      // Clear error state if either field is empty or focused
      this.passwordConfirmationTarget.classList.remove(this.invalidClass)
      this.passwordErrorTarget.style.display = 'none'
      this.passwordConfirmationTarget.setCustomValidity('')
    }
  }

  // Updates the greeting when nickname changes
  updateGreeting() {
    const nickname = this.nicknameTarget.value
    if (nickname) {
      this.pretextTarget.classList.add("text-weaker")
      this.greetingTarget.innerHTML = nickname
      this.greetingTarget.classList.add("text-stronger")
      this.posttextTarget.classList.add("text-weaker")
    } else {
      this.pretextTarget.classList.remove("text-weaker")
      this.greetingTarget.innerHTML = 'your name'
      this.greetingTarget.classList.remove("text-stronger")
      this.posttextTarget.classList.remove("text-weaker")
    }
  }

  // Formats the date as user types
  formatDate(event) {
    let input = event.target
    let value = input.value

    // If they're deleting, don't interfere
    if (value.length < this.oldValue?.length) {
      this.oldValue = value
      return
    }

    // If they manually typed a slash where we'd put one, keep it
    if ((value.length === 2 && value.indexOf('/') === -1) ||
        (value.length === 5 && value.lastIndexOf('/') === 2)) {
      value = value + '/'
    }

    // Remove any extra slashes or non-digits
    value = value.replace(/[^\d/]/g, '')  // Remove non-digits/non-slashes
                .replace(/\/{2,}/g, '/')   // Replace multiple slashes with single slash

    // Make sure we don't have too many chars
    if (value.includes('/')) {
      const parts = value.split('/')
      if (parts[0]) parts[0] = parts[0].slice(0, 2)
      if (parts[1]) parts[1] = parts[1].slice(0, 2)
      if (parts[2]) parts[2] = parts[2].slice(0, 4)
      value = parts.join('/')
    }

    input.value = value
    this.oldValue = value

    // Validate age whenever the date changes
    this.validateAge(event)
  }

  // Validates if the user is 21 or older
  validateAge(event) {
    const input = event.target
    const parts = input.value.split('/')
    if (parts.length !== 3) return

    const month = parseInt(parts[0]) - 1
    const day = parseInt(parts[1])
    const year = parseInt(parts[2])

    const dob = new Date(year, month, day)
    const today = new Date()
    let age = today.getFullYear() - dob.getFullYear()
    const monthDiff = today.getMonth() - dob.getMonth()

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--
    }

    const isValid = age >= 21 && this.isValidDate(month + 1, day, year)

    if (isValid) {
      input.classList.remove(this.invalidClass)
      this.ageErrorTarget.style.display = 'none'
      this.isOfAgeTarget.value = '1'
      input.setCustomValidity('')
    } else {
      input.classList.add(this.invalidClass)
      this.ageErrorTarget.style.display = 'block'
      this.isOfAgeTarget.value = '0'
      input.setCustomValidity('You must be at least 21 years old.')
    }
  }

  // Validates form submission
  validateForm(event) {
    // Check if passwords match
    const password = this.passwordTarget.value
    const confirmation = this.passwordConfirmationTarget.value
    const passwordsMatch = password === confirmation
    
    // Check if age requirement is met
    const ageRequirementMet = this.isOfAgeTarget.value === '1'
    
    // Only prevent submission if validation fails
    if (!ageRequirementMet) {
      event.preventDefault()
      this.dobInputTarget.classList.add(this.invalidClass)
      this.ageErrorTarget.style.display = 'block'
    }
    
    if (!passwordsMatch) {
      event.preventDefault()
      this.passwordConfirmationTarget.classList.add(this.invalidClass)
      this.passwordErrorTarget.style.display = 'block'
    }
    
    // If all validations pass, the form will be submitted normally
  }

  // Helper method to validate date
  isValidDate(month, day, year) {
    const date = new Date(year, month - 1, day)
    return date.getMonth() === month - 1 &&
           date.getDate() === day &&
           date.getFullYear() === year &&
           date <= new Date()
  }
}
