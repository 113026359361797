// theme_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["icon"]

  connect() {
    // Check for saved preference
    const savedTheme = localStorage.getItem('theme') || 'auto'
    this.setTheme(savedTheme)
    this.updateIcon(savedTheme)
  }

  toggle() {
    event.preventDefault()
    const currentTheme = localStorage.getItem('theme') || 'auto'
    const themes = ['light', 'dark', 'auto']
    const nextIndex = (themes.indexOf(currentTheme) + 1) % themes.length
    const newTheme = themes[nextIndex]

    this.setTheme(newTheme)
    this.updateIcon(newTheme)
  }

  setTheme(theme) {
    localStorage.setItem('theme', theme)

    if (theme === 'auto') {
      const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches
      document.documentElement.setAttribute('data-bs-theme', isDark ? 'dark' : 'light')
    } else {
      document.documentElement.setAttribute('data-bs-theme', theme)
    }
  }

  updateIcon(theme) {
    const iconClass = {
      light: 'fa-sun',
      dark: 'fa-moon',
      auto: 'fa-circle-half-stroke'
    }[theme]

    this.iconTarget.className = `fa-solid ${iconClass}`
  }
}
